import $ from 'jquery'
import Cookies from 'js-cookie'

export function initCookiePolicy() {
  if (
    Cookies.get('shown-cookie-policy') &&
    !window.location.href.match(/show-cookie-policy/)
  ) {
    return
  }

  const policy = $('.js-cookie-policy')
  const close = $('.js-cookie-policy-close')

  policy.show()
  Cookies.set('shown-cookie-policy', 1)

  close.on('click', (e) => {
    e.preventDefault()

    $('.js-cookie-policy').remove()
  })
}
