// pick what you want, but order is important
import 'bootstrap-sass/assets/javascripts/bootstrap/transition'
import 'bootstrap-sass/assets/javascripts/bootstrap/alert'
// import 'bootstrap-sass/assets/javascripts/bootstrap/button';
// import 'bootstrap-sass/assets/javascripts/bootstrap/carousel';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse'
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown'
import 'bootstrap-sass/assets/javascripts/bootstrap/modal'
import 'bootstrap-sass/assets/javascripts/bootstrap/tab'
// import 'bootstrap-sass/assets/javascripts/bootstrap/affix';
// import 'bootstrap-sass/assets/javascripts/bootstrap/scrollspy';
import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip'
import 'bootstrap-sass/assets/javascripts/bootstrap/popover'
