import $ from './libs/jquery'
import './libs/bootstrap'
import 'jquery-validation'

import {
  initBasicSlider,
  initProductSlider,
  initQuotesSlider,
  initStandardSlider,
  initImageTextSlider
} from './addons/slick'
import { initDesktopMenu, initMobileMenu } from './addons/header'

import { initAccordions } from './addons/accordion'
import { initBlog } from './addons/blog'
import { initCookiePolicy } from './addons/cookie-policy'
import initFormLocale from './addons/forms'
import { initLocalScroll } from './addons/localscroll'
import { initModalBoxes } from './addons/modal'
import { initSearchFiltersForm } from './addons/search'
import { noscript } from './addons/utils'
import { showTabFromHash } from './addons/tabs'
import svg4everybody from 'svg4everybody'
import { initParallax } from './addons/parallax'

svg4everybody()

$(() => {
  noscript()
  initMobileMenu()
  initDesktopMenu()
  initStandardSlider()
  initProductSlider()
  initQuotesSlider()
  initBasicSlider()
  initImageTextSlider()
  initBlog()
  initModalBoxes()
  initCookiePolicy()
  initAccordions()
  initSearchFiltersForm()
  initLocalScroll()
  initFormLocale()
  initParallax()

  $(window)
    .on('hashchange', () => {
      showTabFromHash()
    })
    .trigger('hashchange')
})

/**
 * Salesforce Click Event forwarder.

$('[data-sfmc]').click(function(e) {

  // If this is a link, stop the redirect so we have
  // enough time to push our SF events.
  let href = $(this).attr('href');
  if (href) {
    e.preventDefault();
  }

  if (window._etmc === undefined) {
    console.log('_etmc not loaded or blocked');
    if (href){
        console.log('redirecting without tracking the click');
        window.location = href;
    }
    return null;
  }

  // CLICK-CTA-BUTTON-NEO-VIDEO__https://www.idexx.com/en/veterinary/software-services/neo/
  let BUTTON_ID = `${$(this).data('sfmc')}__${window.location.toString()}`;

  let pageView = ["trackPageView", {
    'item': BUTTON_ID,
  }];
  console.log('_etmc pageView on click event: ', pageView);
  window._etmc.push(pageView);

  let updateItem = ["updateItem", {
    "item": BUTTON_ID,
    "name": document.title,
    "url": BUTTON_ID,
    "available": "Y",
    "item_type": "content",
  }];
  console.log('_etmc updateItem on click event: ', updateItem);
  window._etmc.push(updateItem);

  // Redirect the original link
  if (href) {
    console.log('_etmc redirect to original link');
    window.location = href;
  }

  return true;
});
 */

/**
 * VSS Section Nav
 **/

$('.feature-menu .section-name button.section-nav-toggle').click(function (e) {
  const el = $(this)
  const container = el.closest('.container')
  const menu = container.find('.section-menu')

  if (el.hasClass('collapsed')) {
    el.removeClass('collapsed')
    menu.addClass('expanded')
  } else {
    el.addClass('collapsed')
    menu.removeClass('expanded')
  }
})

const btn = $('.back-to-top')

$(window).scroll(function () {
  if ($(window).scrollTop() > 300) {
    btn.addClass('show')
  } else {
    btn.removeClass('show')
  }
})

btn.on('click', function (e) {
  e.preventDefault()
  $('html, body').animate({ scrollTop: 0 }, '300')
})

/**
 * Automatically logs the user in when the webpage loads.
 */
window.autologin = function () {
  const pageEditUrl = `${window.location.pathname}?edit`
  if (!!window.CMS) {
    window.location.href = pageEditUrl
  } else {
    const nextParam = encodeURIComponent(pageEditUrl)
    window.location.href = `/admin/login/?autologin=1&next=${nextParam}`
  }
}
