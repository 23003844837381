import $ from 'jquery'
import { scrollTo } from './utils'

const defaults = {
  offset: -20,
  scrollTime: 250
}

export function initLocalScroll() {
  $(document).on('click', '.js-localscroll[href^="#"]', function (e) {
    e.preventDefault()

    const link = $(this)
    const href = link.attr('href')
    const offset = link.data('offset')
    const time = link.data('time')
    const options = $.extend(defaults, { offset, time })
    let target

    try {
      target = $(href)
    } catch (e) {} // eslint-disable-line

    if (!target) {
      return
    }

    const top = target.offset().top

    scrollTo(top, options.scrollTime).then(function () {
      if (window.history.pushState) {
        window.history.pushState('', {}, href)
      } else {
        window.location.hash = href
      }
    })
  })
}
