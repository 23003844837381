import $ from 'jquery'
import Breakpoint from './responsive'

function preventTouchScrolling(element, namespace) {
  element.on('touchmove.cms.preventscroll.' + namespace, function (e) {
    e.preventDefault()
  })
}

function allowTouchScrolling(element, namespace) {
  element.off('touchmove.cms.preventscroll.' + namespace)
}

function isMobileMenu() {
  return Breakpoint.is('xs') || Breakpoint.is('sm')
}

export function initDesktopMenu() {
  // when hovering over top level items on large screens - toggle dropdown programmatically
  const topLevelDropdowns = $('.navbar-main > .navbar-nav > .dropdown')

  topLevelDropdowns.on('mouseenter mouseleave', function (e) {
    if (isMobileMenu()) {
      return
    }

    if (e.type === 'mouseenter') {
      $(this).addClass('open').attr('aria-expanded', true)
    } else {
      $(this).removeClass('open').removeAttr('aria-expanded')
    }
  })

  topLevelDropdowns.on('touchstart click', function (e) {
    if (isMobileMenu()) {
      return
    }

    const dropdownToggle = $(this)

    // touchstart normally happens before click,
    // that's the only reliable way to detect if the device
    // was operated with touch (normally windows tablets and ipad pro)
    if (e.type === 'touchstart') {
      dropdownToggle.data('just-touched', true)
    }
    if (e.type === 'click') {
      if (dropdownToggle.data('just-touched')) {
        e.preventDefault()
        dropdownToggle.data('just-touched', false)
      }
    }
  })

  $(window)
    .on('change:breakpoint', function () {
      if (isMobileMenu()) {
        // init dropdowns
        topLevelDropdowns.find('> a').attr('data-toggle', 'dropdown').dropdown()
      } else {
        // At this point we need to destroy dropdowns because it prevents the default
        // action on the toggle (opening a link), and since
        // bootstrap doesn't support "hover" dropdowns and it doesn't have destroy
        // method we have to do it manually
        topLevelDropdowns
          .find('> a')
          .removeAttr('data-toggle')
          .off('click.bs.dropdown')
          .data('bs.dropdown', null)
      }
    })
    .trigger('change:breakpoint', [Breakpoint.current()])
}

export function initMobileMenu() {
  const toggle = $('.js-header-nav-toggle')
  const mobileMenu = $('.js-navbar-header')
  const html = $('html')

  // close button lives outside it's normal place so we toggle it manually
  mobileMenu.on('show.bs.collapse hide.bs.collapse', () => {
    toggle.toggleClass('collapsed')
    html.toggleClass('mobile-menu-open')

    if (html.hasClass('mobile-menu-open')) {
      preventTouchScrolling(html)
    } else {
      allowTouchScrolling(html)
    }
  })

  $('.header .dropdown').on('show.bs.dropdown hide.bs.dropdown', (e) => {
    const currentDropdown = $(e.target)

    // by default bootstrap closes all the dropdowns, so we need to keep parents open
    // when we are deep in it
    currentDropdown
      .parents('.dropdown')
      .addClass('open')
      .attr('aria-expanded', true)
  })

  $('.megamenu-links-title').on('click', (e) => {
    if (!isMobileMenu()) {
      e.stopPropagation()
    }
  })

  // when opening other header dropdowns - hide the mobile menu
  $(
    '.country-picker.dropdown, .header-top-menu-waffle.dropdown, .header-search-form.dropdown'
  ).on('show.bs.dropdown', () => {
    mobileMenu.collapse('hide')
  })

  // noop click handler so ios bubbles the event
  $('.megamenu-mobile-title').on('click', $.noop)

  $('.navbar-collapse').on('touchmove', (e) => {
    if (html.hasClass('mobile-menu-open')) {
      e.stopPropagation()
    }
  })
}
